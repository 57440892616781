<template>
	<div class="pui-form">
		<portcallclearancesstatus-modals :modelName="modelName"></portcallclearancesstatus-modals>
		<pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement">
			<portcallclearancesstatus-form-header :modelPk="modelPk"></portcallclearancesstatus-form-header>
		</pui-form-header>
		<portcallclearancesstatus-modals :modelName="modelName"></portcallclearancesstatus-modals>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-tabs v-model="tabmodel" class="ml-3 mb-3" slider-color="primary">
				<v-tab :key="0" :href="'#events'">{{ $t('portcallclearancesstatus.tabs.events') }}</v-tab>
				<v-tab :key="1" :href="'#tlimmigration'" v-if="!isCreatingElement && !isModalDialog">{{
					$t('portcallclearancesstatus.tabs.tlimmigration')
				}}</v-tab>
				<v-tab :key="2" :href="'#tlcustoms'" v-if="!isCreatingElement && !isModalDialog">{{
					$t('portcallclearancesstatus.tabs.tlcustoms')
				}}</v-tab>
				<v-tab :key="3" :href="'#tlharbour'" v-if="!isCreatingElement && !isModalDialog">{{
					$t('portcallclearancesstatus.tabs.tlharbour')
				}}</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" :value="'events'">
					<pui-master-detail
						componentName="clearanceactions-grid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ portcallid: 'portcallid' }"
						:formDisabled="formDisabled"
						:modalDialog="isModalDialog"
					></pui-master-detail>
				</v-tab-item>
				<v-tab-item :key="1" :value="'tlimmigration'" lazy v-if="!isCreatingElement">
					<div class="d-flex justify-center">
						<nsw-events-template :items="itemsImmTab1" :cardLeft="4" :cardRight="1"> </nsw-events-template>
					</div>
				</v-tab-item>
				<v-tab-item :key="2" :value="'tlcustoms'" lazy v-if="!isCreatingElement">
					<div class="d-flex justify-center">
						<nsw-events-template :items="itemsImmTab2" :cardLeft="5" :cardRight="2"> </nsw-events-template>
					</div>
				</v-tab-item>
				<v-tab-item :key="3" :value="'tlharbour'" v-if="!isCreatingElement">
					<div class="d-flex justify-center">
						<nsw-events-template :items="itemsImmTab3" :cardLeft="6" :cardRight="3"> </nsw-events-template></div
				></v-tab-item>
			</v-tabs-items>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import portcallclearancesstatusActions from './PortcallclearancesstatusActions';
import portcallclearancesstatusModals from './PortcallclearancesstatusModals';

export default {
	name: 'portcallclearancesstatus-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'portcallclearancesstatus-modals': portcallclearancesstatusModals
	},
	data() {
		return {
			modelName: 'portcallclearancesstatus',
			actions: portcallclearancesstatusActions.actions,
			itemsImmTab1: [],
			itemsImmTab2: [],
			itemsImmTab3: []
		};
	},
	methods: {
		afterGetData() {
			if (this.$router.currentRoute.path.startsWith('/portcalls/')) {
				localStorage.setItem('tabFromAction', 'portcallclearancesstatus');
			}
			this.getItems();
		},
		async getItems() {
			let items = [];

			await this.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'clearanceactions',
					page: 1,
					rows: null
				},
				(response) => {
					if (response.data && response.data.totalRecords > 0) {
						const filteredDataTab1 = response.data.data.filter(
							(item) => item.portcallid == this.model.portcallid && (item.clearancetypeid === 1 || item.clearancetypeid === 4)
						);
						const filteredDataTab2 = response.data.data.filter(
							(item) => item.portcallid == this.model.portcallid && (item.clearancetypeid === 2 || item.clearancetypeid === 5)
						);
						const filteredDataTab3 = response.data.data.filter(
							(item) => item.portcallid == this.model.portcallid && (item.clearancetypeid === 3 || item.clearancetypeid === 6)
						);
						this.itemsImmTab1 = filteredDataTab1;
						this.itemsImmTab2 = filteredDataTab2;
						this.itemsImmTab3 = filteredDataTab3;
					}
				},
				() => {}
			);
			return items;
		}
	},
	computed: {},
	created() {}
};
</script>
